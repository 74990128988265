import React from "react"
import FotoSynlab from "@components/pageCasosdeExito/image/imgPhotoEmpresa/synlab/FotoSynlab.png"
import FotoSynlab2 from "@components/pageCasosdeExito/image/imgPhotoEmpresa/synlab/FotoSynlab2.png"

//logo de empresas clientes
import Savar from "@components/pageCasosdeExito/image/logoscustomer/Synlab.svg"

//iconos de modulos
import whatsapp from "@components/pageCasosdeExito/image/iconcards/whatsapp.svg"
import chatbots from "@components/pageCasosdeExito/image/iconcards/chatbot.svg"
import livechat from "@components/pageCasosdeExito/image/iconcards/livechat.svg"
import campañas from "@components/pageCasosdeExito/image/iconcards/campañas.svg"

//iconos metricas
import metrica1 from "@components/pageCasosdeExito/image/imgMetric/synlab/img1.svg"
import metrica2 from "@components/pageCasosdeExito/image/imgMetric/synlab/img2.svg"
import metrica3 from "@components/pageCasosdeExito/image/imgMetric/synlab/img3.svg"

const text1 = (
  <span className="">
    SYNLAB Perú, es parte de SYNLAB, el laboratorio clínico más grande de
    Europa, presente en más de 34 países, produciendo más de 600 millones de
    pruebas anuales, facturando 3.76 billones de euros y que cuenta con más de
    30k colaboradores.
    <br /> <br />
    Su operación en Perú ha migrado sus soluciones de Contact Center con
    Beex, logrando mejorar en 95% su tiempo de primera respuesta en redes
    sociales, así como las métricas más importantes de satisfacción gracias a la
    gestión omnicanal que les brinda Beex.
  </span>
)

const text2 = (
  <span>
    SYNLAB, como laboratorio clínico presente en más de 34 países, ofrece una
    gama completa de servicios de diagnósticos médicos para el usuario, la
    práctica clínica y para el sector salud en general. La compañía dispone de
    un amplio equipo de expertos capaz de asesorar de forma precisa sobre el
    tipo de diagnóstico recomendado para cada caso, así como ayudar en la
    interpretación de resultados.
    <br /> <br />
    En busca de mejorar la experiencia de sus usuarios, la compañía decidió
    buscar opciones de plataformas para migrar su operación de Contact Center.
    La idea principal era reforzar su gestión omnicanal y automatizaciones en
    atención al cliente.
    <br /> <br />
    Yesenia Sánchez, jefe de Servicio al Cliente en SYNLAB, ha sido un punto de
    contacto importante desde la toma de decisión de la plataforma a migrar.
    Desde el inicio se puso énfasis en querer integrar sus canales más
    importantes de contacto y poder automatizar la gestión de llamadas, atención
    por redes sociales y chats.
    <br /> <br />
    Bajo esta premisa, Beex fue la plataforma elegida por SYNLAB para migrar
    sus operaciones de servicio al cliente en la nube. Gracias a su genial
    relación costo-beneficio, así como sus funcionalidades para canal de voz y
    digitales, nuestra plataforma fue la elegida por la compañía para
    centralizar sus operaciones.
  </span>
)

const text3 = (
  <p className="text3-casos" style={{ position: "relative" }}>
    “Ahora somos más versátiles con nuestros usuarios, brindándoles comodidad
    durante la comunicación, lo que nos permite realizar atenciones más
    oportunas. Hemos logrado mejorar en 95% nuestro tiempo de primera respuesta,
    así como un 20% de atenciones cerradas sin necesidad de algún agente.”
  </p>
)

const text4 = (
  <span>
    Como nos cuenta Yesenia post-implementación, la decisión de la compañía fue
    la acertada ya que Beex les brindaba lo que buscaban y más. Ahora cuentan
    con un escenario amplio de atención omnicanal, dando a sus usuarios la
    comodidad de comunicación a través de cualquiera de sus canales,
    asegurándole la atención de un profesional en el menor tiempo posible.
  </span>
)

const text5 = (
  <span>
    Como lo mencionamos, SYNLAB tenía como objetivo unificar sus canales
    principales de contacto con sus clientes. Hasta antes de la implementación
    de Beex, la compañía no tenía una vista 360 de las interacciones de sus
    clientes, que los contactaban por teléfono, WhatsApp y redes sociales.
    <br /> <br />
    Por ello, el primer paso fue integrar estos canales en Beex. Se inició con
    los números de centrales telefónicas, los cuáles se implementaron con IVR
    automatizados y enrutamientos de llamadas a colas de agentes en específicos
    por áreas y casos.
    <br /> <br />
    Por el lado de los canales digitales, el primer canal a dar de alta fue
    WhatsApp. Para esto, SYNLAB accedió a la versión Business API oficial,
    ganando funcionalidades que hasta ahora no tenían, siendo las principales
    los chatbots para servicio al cliente y el enrutamiento inteligente de casos
    a sus agentes.
    <br /> <br />
    Además de estos canales de vital importancia, se agregaron el de Messenger e
    Instagram, ya que muchos de sus clientes usaban redes sociales para
    contactarlos. Y, como menciona Yesenia, esto ha hecho que su operación pueda
    decidir su propia manera de gestionar a sus clientes, organizar su
    información para analizarla adecuadamente y canalizar eficientemente sus
    atenciones.
  </span>
)
const text6 = (
  <span>
    “Ahora tenemos la flexibilidad para realizar nuestras actividades con el
    mismo nivel de servicio desde cualquier canal. Además, contamos con reportes
    que nos permiten un análisis adecuado que nos ayuda a mantener un orden en
    nuestra atención. Esto nos ha llevado a mejoras importantes, como la
    disminución en casi 20 seg. en nuestras respuestas telefónicas.”
  </span>
)

const text7 = (
  <span>
    Una vez integrados sus canales de atención, un punto vital para la gestión
    de atención al cliente de SYNLAB era el automatizar sus procesos de
    atenciones por canal de voz y chat, y, para esto, se diseñaron y sumaron IVR
    y chatbots.
    <br /> <br />
    Para su canal de voz, se diseñó un IVR centralizado para sus diferentes
    áreas. Este IVR logra brindar información en tiempo real a sus clientes,
    disminuyendo el contacto por teléfono para preguntas frecuentes. Además,
    tiene la capacidad de brindar información relevante según el tipo de
    paciente.
    <br /> <br />
    Para sus canales digitales, como WhatsApp, se implementaron chatbots que han
    logrado mejorar sus tiempos de primera respuesta y resolución de casos.
    Estos chatbots están configurados para respondes consultas frecuentes y que
    sus usuarios puedan autogestionar sus procesos de atención.
    <br /> <br />
    Yesenia nos cuenta que el uso de bots en su operación ha reducido la
    sobrecarga de atención, logrando la autogestión para la mayoría de sus
    casos.
  </span>
)
const text8 = (
  <span>
    “Entre canal de voz y chat, casi el 20% de consultas son resueltas por
    nuestros bots. Así, disminuimos el flujo de mensajes que manejan nuestros
    agentes, mejorando métricas importantes de gestión, como nuestros tiempos de
    respuesta y resolución de casos.”
  </span>
)

const text9 = (
  <span>
    Como es costumbre, Beex brindó un soporte A1 desde el inicio a SYNLAB.
    Partiendo de la migración de su operación desde otras plataformas, hasta el
    soporte post-implementación.
    <br /> <br />
    El éxito ha radicado en la gran relación creada entre los equipos de ambas
    compañías. Desde el contacto comercial hasta el soporte brindado para cada
    requerimiento de SYNLAB, las gestiones entre equipos ha sido la mejor y ha
    llevado al éxito total de esta implementación.
    <br /> <br />
    Yesenia refuerza esto, confirmando además que siempre recomendaría nuestras
    soluciones porque son capaces de permitir en el corto plazo tener el control
    total de su gestión, ayudando siempre a nuestros clientes en el camino y
    aportando dinamismo a sus operaciones.
  </span>
)
const text10 = (
  <span>
    “El soporte y acompañamiento de Beex es A1. Puedo confirmar que es un
    servicio de excelencia, contando con un equipo de servicio al cliente
    rápido, preciso y empático.”
  </span>
)

const text11 = (
  <p style={{ marginBottom: "70px" }}>
    Estos son algunos procesos con los que ayudamos a
    SYNLAB en su operación de atención al cliente:
  </p>
)

const data = {
  intro: {
    image: Savar,
    link: "https://www.youtube.com/watch?v=lVUrbdYUKRg",
    classlogo: "logo__clientes--kontigo",
    text1: text1,
    text2: text2,
    text3: text3,
    fotoone: FotoSynlab,
    text4: text4,
    title5: "Gestión omnicanal",
    text5: text5,
    text6: text6,
    title7: "IVR y chatbots",
    text7: text7,
    text8: text8,
    fototwo: FotoSynlab2,
    title9: "Experiencia de soporte",
    text9: text9,
    text10: text10,
    title11: "Experiencia de soporte",
    text11: text11,

    metric1: metrica1,
    metric2: metrica2,
    metric3: metrica3,
  },

  cards: [
    {
      title: "Omnicanalidad",
      img: livechat,
      info:
        "Integración de canal telefónico, WhatsApp, Messenger e Instagram en Beex, logrando tener conversaciones por estos canales a través de un solo hilo de conversación omnicanal en una sola ventana de conversación.",
    },
    {
      title: "Telefonía inbound",
      img: campañas,
      info:
        "Enrutamos las llamadas entrantes en la central telefónica de SYNLAB, ante las consultas o dudas de sus usuarios, respetando sus tiempos de atención, acorde a sus niveles de servicio.",
    },
    {
      title: "Chatbots",
      img: chatbots,
      info:
        "Implementación de chatbots por los canales de más interacción con sus clientes, como WhatsApp, Messenger e Instagram. Diseño y creación por rangos horarios para compartir respuestas eficaces y rápidas, con disponibilidad 24x7, los 365 días del año.",
    },
    {
      title: "WhatsApp",
      img: whatsapp,
      info:
        "Gestión conversacional de atención al cliente vía WhatsApp Business API, permitiendo que sus clientes conversen con SYNLAB mediante la App más usada a nivel mundial. Contando con enrutamiento inteligente, asignación de casos y más.",
    },
  ],
}

export default data
